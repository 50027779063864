<template>
  <div class="processDetails">
    <el-dialog
      title="异常订单处理记录审核"
      :visible="dialogVisibleDetails"
      @close="consoleDetail"
      width="70%"
    >
      <!-- <h2 class="parkingInfo">停车订单列表</h2> -->
      <h2 class="title" style="padding-top: 10px">
        <div class="title_icon"></div>
        <span class="titlecontent">停车订单列表</span>
      </h2>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        style="width: 100% !important"
        :header-row-class-name="headerTitle"
      >
        <el-table-column
          align="center"
          :label="item.label"
          :min-width="item.width"
          v-for="item in tableDetails"
          :key="item.prop"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'parkRecordId'"
              v-clipboard:copy="scope.row.parkRecordVO.parkRecordId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.parkRecordVO.parkRecordId }}</p>
            </span>
            <!-- <span v-else-if="item.prop == 'lackMoney'">
              <p>
                {{
                  item.formatter
                    ? item.formatter(scope.row)
                    : scope.row.parkRecordVO[item.prop]
                }}
              </p>
            </span> -->
            <span v-else>
              <p>
                {{
                  item.formatter
                    ? item.formatter(scope.row.parkRecordVO)
                    : scope.row.parkRecordVO[item.prop]
                }}
              </p>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 详情 -->
      <div class="carDetail">
        <h2 class="parkingInfo">
          道路/车场详情
          <div class="parkingInfoLine"></div>
        </h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">道路/车场：</span>
              <span>{{ tableData[0].parkRecordVO.parkName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">商户名称:</span>
              <span>{{ tableData[0].parkRecordVO.operationName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">车场类型:</span>
              <span>{{ tableData[0].parkRecordVO.parkTypeName }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <!-- <div class="detailsItem">
              <span class="detailsSpan">城市：</span>
              <span>{{ tableData[0].parkRecordVO.cityName }}</span>
            </div> -->
            <div class="detailsItem">
              <span class="detailsSpan">区域：</span>
              <span>{{ tableData[0].parkRecordVO.areaName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">泊位号：</span>
              <span>{{ tableData[0].parkRecordVO.berthCode }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <h2 class="parkingInfo">
          车辆详情
          <div class="parkingInfoLine"></div>
        </h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">车牌号：</span>
              <span
                v-if="
                  this.formData.treatmentVlaue == 2 &&
                  tableData[0].originValue != tableData[0].currentValue
                "
              >
                <u class="ustyleLine"> {{ tableData[0].originValue }}</u>
                <a class="uStyleItem"> {{ tableData[0].currentValue }}</a>
              </span>
              <span v-else>{{ tableData[0].parkRecordVO.plateNumber }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">入场时间：</span>
              <span>{{ tableData[0].parkRecordVO.strEntryTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">入场记录来源：</span>
              <span>{{ tableData[0].parkRecordVO.entryDataSourceName }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出入场图像：</span>
              <span
                class="orderNumberStyle"
                style="cursor: pointer"
                @click="lookPicture(tableData[0].parkRecordVO)"
                >查看</span
              >
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">停车时长：</span>
              <span>{{ tableData[0].parkRecordVO.parkTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出场时间：</span>
              <span
                v-if="
                  formData.treatmentVlaue == 1 &&
                  tableData[0].originValue != tableData[0].currentValue
                "
              >
                <u
                  class="ustyleLine"
                  v-if="tableData[0].parkRecordVO.strExitTime && tableData[0].originValue"
                >
                  {{ tableData[0].originValue }}</u
                >
                <a class="uStyleItem"> {{ tableData[0].currentValue }}</a>
              </span>
              <span v-else>{{ tableData[0].parkRecordVO.strExitTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">出场记录来源：</span>
              <span>{{ tableData[0].parkRecordVO.exitDataSourceName }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <h2 class="parkingInfo">
          订单详情
          <div class="parkingInfoLine"></div>
        </h2>
        <el-row>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">订单金额：</span>
              <span v-if="tableData[0].originSumShouldPay == tableData[0].modifySumShouldPay"
                >{{ getDiddMoeny(tableData[0].originSumShouldPay) }}元</span
              >
              <span v-else>
                <u class="ustyleLine"> {{ getDiddMoeny(tableData[0].originSumShouldPay) }}元</u>
                <a class="uStyleItem"> {{ getDiddMoeny(tableData[0].modifySumShouldPay) }}元</a>
              </span>
            </div>
          </el-col>
          <el-col :span="12" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">优惠总计：</span>
              <span v-if="tableData[0].originSumAgioMoney == tableData[0].modifySumAgioMoney"
                >{{ getDiddMoeny(tableData[0].originSumAgioMoney) }}元</span
              >
              <span v-else>
                <u class="ustyleLine"> {{ getDiddMoeny(tableData[0].originSumAgioMoney) }}元</u>
                <a class="uStyleItem"> {{ getDiddMoeny(tableData[0].modifySumAgioMoney) }}元</a>
              </span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">实付总计：</span>
              <span v-if="tableData[0].originSumActualMoney == tableData[0].modifySumActualMoney"
                >{{ getDiddMoeny(tableData[0].originSumActualMoney) }}元</span
              >
              <span v-else>
                <u class="ustyleLine"> {{ getDiddMoeny(tableData[0].originSumActualMoney) }}元</u>
                <a class="uStyleItem"> {{ getDiddMoeny(tableData[0].modifySumActualMoney) }}元</a>
              </span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">欠费总计：</span>
              <span v-if="tableData[0].originSumLackMoney == tableData[0].modifySumLackMoney"
                >{{ getDiddMoeny(tableData[0].originSumLackMoney) }}元</span
              >
              <span v-else>
                <u class="ustyleLine"> {{ getDiddMoeny(tableData[0].originSumLackMoney) }}元</u>
                <a class="uStyleItem"> {{ getDiddMoeny(tableData[0].modifySumLackMoney) }}元</a>
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-divider></el-divider>
      <!-- 连续停车订单处理 -->
      <div v-show="beforeRecordsData.length > 0">
        <h2 class="title" style="padding-top: 10px">
          <div class="title_icon"></div>
          <span class="titlecontent">连续停车订单处理 </span>
        </h2>
        <div>
          <div class="title2" v-if="formData.treatmentVlaue == 2 && beforeRecordsData.length > 0">
            <span class="tip">更改前</span> {{ beforeRecordsData[0].plateNumber }}
          </div>
          <processExceptionDetail
            v-if="beforeRecordsData.length > 0"
            :tableData="beforeRecordsData"
          ></processExceptionDetail>
          <div
            class="title2 afterTitlte"
            v-if="formData.treatmentVlaue == 2 && afterRecordsData.length > 0"
          >
            <span class="tip">更改后</span> {{ afterRecordsData[0].plateNumber }}
          </div>
          <!-- 修改后车牌 -->
          <processExceptionDetail
            v-if="formData.treatmentVlaue == 2 && afterRecordsData.length > 0"
            :tableData="afterRecordsData"
          ></processExceptionDetail>
        </div>
        <el-divider></el-divider>
      </div>

      <!-- 流程 -->
      <div>
        <h2 class="title" style="padding-top: 10px">
          <div class="title_icon"></div>
          <span class="titlecontent">流程查看 </span>
          <span
            class="orderNumberStyle"
            @click="lookAll"
            style="padding-left: 10px; cursor: pointer"
          >
            {{ lookAllTag ? "返回" : "查看全部" }}</span
          >
        </h2>
        <!-- <el-steps :active="tableData[0].status" align-center>
          <el-step title="发起处理" description>
            <template slot="description">
              <p>
                {{ tableData[0].handleOperatorName }}
                {{ tableData[0].handleTime }}
              </p>
            </template>
          </el-step>
          <span class="stateTip">{{ tableData[0].statusName }}</span>
          <el-step title="申诉审核" description>
            <template slot="description">
              <p>
                {{ tableData[0].status == 0 ? "" :tableData[0].handleOperatorName }}
                {{ tableData[0].appealTime }}
              </p>
            </template>
          </el-step>
        </el-steps> -->
        <div class="step">
          <div class="stepItem">
            <div class="">
              <span :class="false ? 'stepIng' : 'stepS'">1</span>
              <span :class="false ? 'stepLing' : 'stepL'"> </span>
            </div>
            <div class="stepContent">
              <p :class="false ? 'stepContentIng' : ''" style="margin-bottom: 6px">发起处理</p>
              <p :class="false ? 'stepContentIng' : ''">
                {{ tableData[0].handleOperatorName }}
                {{ tableData[0].handleTime }}
              </p>
            </div>
          </div>
          <span class="stepI"> {{ tableData[0].statusName }} </span>
          <div class="stepItem">
            <div class="">
              <span :class="tableData[0].status == 0 ? 'stepL2ing' : 'stepL2'"> </span>
              <span :class="tableData[0].status == 0 ? 'stepIng' : 'stepS'">2</span>
            </div>
            <div class="stepContent">
              <p
                :class="tableData[0].status == 0 ? 'stepContentIng' : ''"
                style="margin-bottom: 6px"
              >
                申诉审核
              </p>
              <p :class="tableData[0].status == 0 ? 'stepContentIng' : ''">
                {{ tableData[0].status == 0 ? "" : tableData[0].handleOperatorName }}
                {{ tableData[0].appealTime }}
              </p>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
      <!--异常停车处理信息 -->
      <el-form ref="formData" :model="formData">
        <div v-show="!lookAllTag">
          <h2 class="title" style="margin-bottom: 16px">
            <div class="title_icon"></div>
            <span class="titlecontent">异常订单处理</span>
          </h2>
          <h2 class="parkingInfo">
            处理理由
            <div class="parkingInfoLine"></div>
          </h2>
          <el-input
            type="textarea"
            :disabled="true"
            placeholder="请输入内容"
            v-model="textarea"
            maxlength="30"
            show-word-limit
          >
          </el-input>
          <!-- <h2 class="parkingInfo">图像照片:</h2>
        <div class="panoramicPhotos">
          <div
            class="picBox"
            v-for="(value, index) in panoramicPhotos"
            :key="index"
          >
            <img
              :src="value.picUrl"
              width="100%"
              height="100%"
              @click="
                showBigPic(
                  'http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg'
                )
              "
            />
          </div>
        </div> -->
        </div>
        <!-- 处理方式 -->
        <el-divider v-if="!lookAllTag"></el-divider>
        <div v-show="!lookAllTag">
          <h2 class="title" style="margin-bottom: 16px">
            <div class="title_icon"></div>
            <span class="titlecontent">处理方式</span>
          </h2>
          <el-row>
            <el-col :span="7">
              <el-form-item label="更改项:">
                <el-select v-model="formData.treatmentVlaue" placeholder="请选择" :disabled="true">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="false">
              更改为:
              <el-date-picker v-model="timeStr" type="datetime" placeholder="选择日期时间">
              </el-date-picker>
            </el-col>
            <el-col :span="7" v-if="formData.treatmentVlaue == 1">
              调整基于:
              <el-select v-model="timeChange" placeholder="请选择" :disabled="true">
                <el-option
                  v-for="item in timeChangeoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8" v-if="formData.treatmentVlaue == 1 && timeChange == 2">
              调整为: 入场时间 +
              <el-input
                placeholder="请输入"
                v-model="timeInput"
                style="width: 100px"
                :disabled="true"
              >
              </el-input>
              秒
            </el-col>
            <el-col :span="8" v-if="formData.treatmentVlaue == 1 && timeChange == 1">
              调整为:
              <el-date-picker
                v-model="timeStr"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
                :disabled="true"
              >
              </el-date-picker>
            </el-col>
            <el-col style="display: flex" :span="12" v-if="formData.treatmentVlaue == 2">
              <div style="height: 100%; line-height: 40px">车牌号:</div>
              <el-select
                v-model="carColor"
                placeholder="请选择"
                style="width: 80px"
                :disabled="true"
              >
                <el-option
                  v-for="item in optionsColor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-form-item label="" prop="plateNumber">
                <el-input
                  v-model="formData.plateNumber"
                  placeholder="请输入内容"
                  style="width: 180px"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" v-if="formData.treatmentVlaue == 3">
              增/减:
              <el-select v-model="optionsAddItem" placeholder="请选择" style="width: 80px" disabled>
                <el-option
                  v-for="item in optionsAdd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6" v-if="formData.treatmentVlaue == 3">
              <el-form-item label="金额-" prop="discountAmount">
                <el-input
                  disabled
                  v-model="formData.discountAmount"
                  placeholder="请输入内容"
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <h2
            class="parkingInfo"
            v-if="tableData[0].statusName == '待审核' && tag == 1 ? false : true"
          >
            审核理由
            <div class="parkingInfoLine"></div>
          </h2>
          <!-- <h2
            class="parkingInfo"
            v-if="
              tableData[0].statusName == '待审核' && tag == 1 ? false : true
            "
          >
            审核理由：
          </h2> -->
          <el-input
            v-if="tableData[0].statusName == '待审核' && tag == 1 ? false : true"
            type="textarea"
            placeholder="请输入内容"
            v-model="appealReason"
            maxlength="30"
            show-word-limit
            :disabled="tag == 2 ? false : true"
          >
          </el-input>
        </div>
        <!-- 流程记录 -->
        <div v-show="lookAllTag">
          <h2 class="title" style="margin-bottom: 16px">
            <div class="title_icon"></div>
            <span class="titlecontent">流程记录</span>
          </h2>
          <div>
            <div class="timelineStyle" v-if="tableData[0].statusName != '待审核'">
              <div class="timeLeft">{{ tableData[0].formatAppealTime }}</div>
              <div class="timelineDou">
                <div>
                  <p>申诉处理审核: {{ tableData[0].statusName }}</p>
                  <div class="timelineContent">
                    <span>{{ tableData[0].appealTime }}</span>
                    <span>来源:{{ tableData[0].operateSourceName }}</span>
                    <span>操作人:{{ tableData[0].appealOperatorName }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="timelineStyle">
              <div class="timeLeft">{{ tableData[0].formatHandleTime }}</div>
              <div class="timelineDou">
                <div>
                  <p>申诉处理提审</p>
                  <div class="timelineContent">
                    <span>操作项</span><span>初始值</span><span>变更值</span>
                  </div>
                </div>
                <div class="timelineContent" v-if="this.formData.treatmentVlaue == 1">
                  <span>{{ tableData[0].appealTypeName }}</span
                  ><span>{{ tableData[0].originValue }}</span
                  ><span>{{ tableData[0].currentValue }}</span>
                </div>
                <div class="timelineContent" v-if="this.formData.treatmentVlaue == 2">
                  <span>{{ tableData[0].appealTypeName }}</span
                  ><span>{{ tableData[0].originValue }}</span
                  ><span>{{ tableData[0].currentValue }}</span>
                </div>
                <div class="timelineContent" v-if="this.formData.treatmentVlaue == 3">
                  <span>{{ tableData[0].appealTypeName }}</span
                  ><span>{{ tableData[0].originValue }}</span
                  ><span>{{ tableData[0].currentValue }}</span>
                </div>
                <div class="timelineContent" v-if="this.formData.treatmentVlaue == 4">
                  <span>{{ tableData[0].appealTypeName }}</span
                  ><span>正常</span><span>免费</span>
                </div>
                <div class="timelineContent" v-if="this.formData.treatmentVlaue == 5">
                  <span>{{ tableData[0].appealTypeName }}</span
                  ><span>正常</span><span>作废</span>
                </div>
                <div class="timelineContent">
                  <span>{{ tableData[0].handleTime }}</span>
                  <span>来源:{{ tableData[0].operateSourceName }}</span>
                  <span>操作人:{{ tableData[0].handleOperatorName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <!-- 关闭 -->
      <div class="closewindow">
        <el-button v-show="lookAllTag" @click="lookAll">返回</el-button>
        <el-button
          v-if="
            tag == 1 &&
            tableData[0].status != 0 &&
            tableData[0].appealType != 4 &&
            tableData[0].appealType != 1
          "
          @click="goException"
          >再次发起</el-button
        >
        <el-button v-if="tag == 2" @click="adoptBack(1)">通过审核</el-button>
        <el-button v-if="tag == 2" @click="adoptBack(2)">退回审核</el-button>
        <el-button @click="consoleDetail">关闭窗口</el-button>
      </div>
    </el-dialog>
    <processPicture
      ref="processPictureID"
      :dialogVisible="dialogVisible"
      @consolePicture="consolePicture"
    ></processPicture>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
import processPicture from "./processPicture";
import processExceptionDetail from "./processExceptionDetail";
export default {
  name: "picDetail",
  data() {
    return {
      beforeRecordsData: [],
      afterRecordsData: [],

      tag: "",
      appealReason: "",
      timeChange: "1",
      timeInput: "",
      appealContentData: "",
      parkOrderAppealId: "",
      bigPic: false,
      picUrl:
        "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
      picUrl1:
        "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
      dialogVisible: false,
      lookAllTag: false,
      reverse: true,
      treatmentVlaue: "",
      timeStr: "",
      carColor: "1",
      optionsAddItem: "1",
      carId: "",
      options: [
        {
          value: "0",
          label: "请选择",
        },
        {
          value: "1",
          label: "调整出场时间",
        },
        {
          value: "2",
          label: "变更车牌号",
        },
        {
          value: "3",
          label: "更改订单金额",
        },
        {
          value: "4",
          label: "免费该订单",
        },
        {
          value: "5",
          label: "作废该订单",
        },
        {
          value: "6",
          label: "仅退款",
        },
      ],
      optionsColor: [
        {
          value: "0",
          label: "蓝",
        },
        {
          value: "1",
          label: "黄",
        },
        {
          value: "2",
          label: "绿",
        },
        {
          value: "3",
          label: "黄绿",
        },
        {
          value: "4",
          label: "黑",
        },
        {
          value: "5",
          label: "白",
        },
      ],
      optionsAdd: [
        {
          value: "1",
          label: "减",
        },
        {
          value: "2",
          label: "加",
        },
      ],
      panoramicPhotos: [
        {
          picUrl:
            "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
        },
        {
          picUrl:
            "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
        },
        {
          picUrl:
            "http://acs-pre-image.oss-cn-zhangjiakou.aliyuncs.com/busy/20211130/PA211101151552/7602decf3d0419d5b2b5bf75ec37cbb2.jpg",
        },
      ],
      timeChangeoptions: [
        {
          value: "1",
          label: "具体时间日期",
        },
        {
          value: "2",
          label: "记录入场时间",
        },
      ],
      textarea: "",
      tableData: [{ parkRecordVO: {} }],
      formData: {
        textarea: "",
        treatmentVlaue: "1",
        discountAmount: "",
        plateNumber: "",
      },
    };
  },
  watch: {
    parkRecordIdData: {
      handler(curVal, oldVal) {},
      deep: true,
    },
  },
  components: {
    processPicture,
    processExceptionDetail,
  },
  created() {
    console.log("测试1");
  },
  mounted() {
    console.log("测试");
  },
  computed: {},
  methods: {
    headerTitle(row, rowIndex) {
      return "headerTitleS";
    },
    rowItem(row, rowIndex) {
      let dataRow = row.rowIndex % 2;
      if (dataRow == 1) {
        return "rowItem";
      } else {
        return "rowItem";
      }
    },
    getGetDifferenceMoney(data, strExitTime, carId) {
      console.log("data_1", data);
      let dataItem = {
        entryTime: data.parkRecordVO.strEntryTime,
        exitTime: strExitTime,
        carId: carId,
        parkId: data.parkRecordVO.parkId,
        parkRecordId: data.parkRecordVO.parkRecordId,
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/feeScene/charge/calc", {
            data: dataItem,
          })
          .then((res) => {
            // resolve(getDifference);
            resolve(res);
          })
          .catch((e) => {
            // resolve(getDifference);
            resolve(e);
          });
      });
    },
    goException() {
      this.$router.push({
        name: "exceptionOrder_start",
        params: {
          parkRecordIds: this.tableData[0].businessId,
        },
      });
    },
    adoptBack(status) {
      let dataItem = {
        parkOrderAppealId: this.parkOrderAppealId,
        appealReason: this.appealReason,
        status: status,
      };
      this.$axios
        .post("/acb/2.0/orderAppeal/approve", {
          data: dataItem,
        })
        .then((res) => {
          if (res.state == 0) {
            let statusTitps = status == 2 ? "审核已驳回" : "审核成功";
            this.$alert(statusTitps, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            this.consoleDetail();
          }
        });
    },
    getDiddMoeny(dataMoney) {
      if (dataMoney) {
        return Number(dataMoney / 100).toFixed(2);
      } else {
        return "0.00";
      }
    },
    // 申述记录详情
    parkOrderAppeal(data, tag) {
      // tag 2 审核界面 1 是列表页面
      this.tag = tag;
      this.parkOrderAppealId = data;
      console.log("refundRecordIdFnData-tag->", tag);
      this.$axios
        .get("/acb/2.0/orderAppeal/detail", {
          data: {
            parkOrderAppealId: data,
          },
        })
        .then(async (res) => {
          this.tableData = [];
          this.tableData.push(res.value);
          let appealContent = JSON.parse(res.value.appealContent);
          this.appealContentData = appealContent;
          this.textarea = res.value.handleReason;
          this.appealReason = res.value.appealReason;
          this.formData.treatmentVlaue = res.value.appealType + 1 + "";
          if (this.formData.treatmentVlaue == 1) {
            // 出场时间
            this.timeChange = appealContent.exitTimeModifyType + 1 + "";
            if (this.timeChange == 1) {
              this.timeStr = appealContent.modifyExitTime;
            } else {
              this.timeInput = appealContent.exitTimeModifyValue;
            }
          } else if (this.formData.treatmentVlaue == 2) {
            let carNum = res.value.currentValue.split(":");
            console.log("carNum", carNum);
            this.formData.plateNumber = carNum[1];
            this.carColor = this.getCarColorValue(carNum[0]);
          } else if (this.formData.treatmentVlaue == 3) {
            this.formData.discountAmount = appealContent.diffValue / 100;
            this.tableData[0].modifySumShouldPay = appealContent.modifyShouldPay;
          } else if (this.formData.treatmentVlaue == 4) {
          } else if (this.formData.treatmentVlaue == 5) {
          }
          // sso
          this.beforeRecordsData = res.value.beforeRecordList ?? [];
          this.afterRecordsData = res.value.afterRecordList ?? [];

          console.log("appealContent-->", appealContent);
        });
    },
    showBigPic(url) {
      // if (!url) {
      //   return false;
      // }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    getCarColorValue(carColorStr) {
      let color = this.optionsColor.filter((item) => item.label == carColorStr);
      console.log("color--", color);
      return color[0].value;
    },
    lookPicture(data) {
      this.dialogVisible = true;
      this.$refs.processPictureID.getPic(data.entryOprNum, data.exitOprNum);
    },
    consolePicture() {
      this.dialogVisible = false;
    },
    lookAll() {
      console.log("sdi", this.lookAllTag);
      if (this.lookAllTag) {
        this.lookAllTag = false;
      } else {
        this.lookAllTag = true;
      }
    },
    consoleDetail() {
      console.log("ces");
      this.appealReason = "";
      this.$emit("consoleDetails");
      // this.$emit('update:visible', false)
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
  },
  props: {
    dialogVisibleDetails: {
      default: false,
    },
    infoSize: {
      type: Number,
      default: 6,
    },
    tableDetails: {
      default: function () {
        return [];
      },
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.title2
    padding 15px 10px
    color #333
    background-color #f3f9fe
.afterTitlte {
  margin-top: 20px;
  color: #de364c;
}
.tip{
  font-weight: bold;
}
.stepContentIng{
  color: #DCDFE6;
}
.stepIng{
  border: 3px solid #DCDFE6;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  box-sizing: border-box;
  text-align: center;
  line-height: 24px;
  color: #DCDFE6;
}
.stepLing{
  display: inline-block;
  width: 42%;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 54%;
  background: #DCDFE6;
}
.stepL2ing{
  display: inline-block;
  width: 42%;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 3%;
  background: #DCDFE6;
}
.stepContent{
  padding-top: 10px
}
.stepI{
  line-height: 22px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  color: #0f6eff;
}
.step{
  display:flex;
  width: 60%;
  margin: 0 auto;
}
.stepItem{
  flex: 1;
  position: relative;
  text-align: center;
}
.stepS{
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid #000;
    border-radius: 15px;
    box-sizing: border-box;
    text-align: center;
    line-height: 24px;
    color: #000000;
}
.stepL{
  display: inline-block;
  width: 42%;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 54%;
  background: #000;
}
.stepL2{
  display: inline-block;
  width: 42%;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 3%;
  background: #000;
}
.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.timelineStyle {
  display: flex;
  min-height: 100px;
}

.timelineDou {
  border-left: 2px solid #C0C4CC;
  position: relative;
  padding: 0px 0px 0px 20px;
}

.timeLeft {
  padding: 0px 20px 0px 10px;
}

.timelineContent span {
  display: inline-block;
  width: 200px;
  padding-top: 20px;
}

.timelineDou:after {
  content: '';
  position: absolute;
  left: -6px;
  top: 0px;
  width: 10px;
  height: 10px;
  background-color: #0f6eff;
  border-radius: 4px;
}

.panoramicPhotos {
  display: flex;
}

.picBox {
  width: 200px;
  height: 150px;
  padding: 5px;
}

.bottomLine {
  // border-bottom: 1px solid #F4F6FA;
}

.stateTip {
  z-index: 9999;
  background-color: #F4F6FA;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #0f6eff;
  border-radius: 10px;
  font-weight: 700;
}

.detailsItem {
  margin-bottom: 4px;
  // padding-left: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.detailsSpan {
  text-align: left;
  display: inline-block;
  padding-right: 5px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #98A9BF;
  padding-bottom: 8px;
}

.orderNumberStyle {
  color: #0f6eff;
}

.closewindow {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.parkingInfo {
  font-family: PingFangSC-Medium, PingFang SC;
  position: relative;
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #000000;
  margin-top: 16px;
}

.parkingInfoLine {
  width: 56px;
  height: 5px;
  background: #0674FB;
  opacity: 0.28;
  transform: translateY(-10px);
}

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}

.ustyleLine {
  text-decoration: line-through;
}

.uStyleItem {
  color: #0f6eff;
  padding-left: 4px;
}

.processLine {
  padding-left: 18%;
}

.titlecontent {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2F2F51;
}

.el-table>>> .headerTitleS th {
  background: #F3F7FF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.el-table>>> td {
  height: 40px;
  padding: 0px;
  background: #F9FBFF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
}
.carDetail{
  padding-left: 48px;
}
</style>
<style lang="stylus">
.processDetails .el-dialog__body {
  padding-top: 4px;
}

.processDetails .el-dialog__header {
  padding: 0 12px;
  background-color: #FFFFFF;
  height: 40px;
  line-height: 40px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 2px 8px 0px rgba(50, 67, 116, 0.3);

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
    color: #323233;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #000000;
    transform: translateY(-50%);
  }
}

.processDetails .el-step.is-center .el-step__line {
  left: 50%;
  right: -63%;
}
</style>
