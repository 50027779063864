var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%!important" },
      attrs: { border: "", data: _vm.tableData },
    },
    [
      _c("el-table-column", {
        attrs: { label: "记录属性" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("recordPropertiesStr"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [_vm._v(" " + _vm._s(scope.row.recordPropertiesStr) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          label: _vm.$t("searchModule.Parking_record_ID"),
          width: "180",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: scope.row.parkRecordId,
                        expression: "scope.row.parkRecordId",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success",
                      },
                    ],
                    class: [
                      _vm.treatmentVlaue == 5 && scope.row.recordProperties == 0
                        ? "ustyleLine"
                        : "",
                      "orderNumberStyle",
                    ],
                  },
                  [_vm._v(" " + _vm._s(scope.row.parkRecordId) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("searchModule.Entry_time"), width: "160" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("entryTime"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [_vm._v(" " + _vm._s(scope.row.entryTime) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("searchModule.Appearance_time"), width: "160" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("exitTime"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [_vm._v(" " + _vm._s(scope.row.exitTime) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("searchModule.Parking_duration"), width: "130" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("parkTime"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [_vm._v(" " + _vm._s(scope.row.parkTime) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: _vm.$t("searchModule.plate_number"), width: "110" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("plateNumber"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [_vm._v(" " + _vm._s(scope.row.plateNumber) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "订单金额(处理前)", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("originSumShouldPay"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.originSumShouldPay || 0) / 100).toFixed(2)
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "订单金额(处理后)", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("modifySumShouldPay"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.modifySumShouldPay || 0) / 100).toFixed(2)
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "欠费金额(处理前)", width: "140  " },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("originSumLackMoney"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.originSumLackMoney || 0) / 100).toFixed(2)
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "欠费金额(处理后)", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("modifySumLackMoney"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.modifySumLackMoney || 0) / 100).toFixed(2)
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "实付金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("originSumActualMoney"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.originSumActualMoney || 0) / 100).toFixed(
                            2
                          )
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "优惠金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("originSumDiscount"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.originSumDiscount || 0) / 100).toFixed(2)
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "应退金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("sumShouldRefundMoney"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.sumShouldRefundMoney || 0) / 100).toFixed(
                            2
                          )
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "实退金额" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      colorRed:
                        scope.row.redFields &&
                        scope.row.redFields.includes("sumActualRefundMoney"),
                      ustyleLine:
                        _vm.treatmentVlaue == 5 &&
                        scope.row.recordProperties == 0,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          ((scope.row.sumActualRefundMoney || 0) / 100).toFixed(
                            2
                          )
                        ) +
                        "元 "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }