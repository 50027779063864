var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "processDetails" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "异常订单处理记录审核",
            visible: _vm.dialogVisibleDetails,
            width: "70%",
          },
          on: { close: _vm.consoleDetail },
        },
        [
          _c(
            "h2",
            { staticClass: "title", staticStyle: { "padding-top": "10px" } },
            [
              _c("div", { staticClass: "title_icon" }),
              _c("span", { staticClass: "titlecontent" }, [
                _vm._v("停车订单列表"),
              ]),
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100% !important" },
              attrs: {
                data: _vm.tableData,
                "header-row-class-name": _vm.headerTitle,
              },
            },
            _vm._l(_vm.tableDetails, function (item) {
              return _c("el-table-column", {
                key: item.prop,
                attrs: {
                  align: "center",
                  label: item.label,
                  "min-width": item.width,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.prop == "parkRecordId"
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value:
                                        scope.row.parkRecordVO.parkRecordId,
                                      expression:
                                        "scope.row.parkRecordVO.parkRecordId",
                                      arg: "copy",
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: _vm.onCopy,
                                      expression: "onCopy",
                                      arg: "success",
                                    },
                                  ],
                                  staticClass: "orderNumberStyle",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.parkRecordVO.parkRecordId
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _c("span", [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.formatter
                                          ? item.formatter(
                                              scope.row.parkRecordVO
                                            )
                                          : scope.row.parkRecordVO[item.prop]
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "carDetail" },
            [
              _c("h2", { staticClass: "parkingInfo" }, [
                _vm._v(" 道路/车场详情 "),
                _c("div", { staticClass: "parkingInfoLine" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "detailsLeft", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("道路/车场："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.parkName)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("商户名称:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.operationName)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("车场类型:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.parkTypeName)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detailsLeft", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("区域："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.areaName)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("泊位号："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.berthCode)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c("h2", { staticClass: "parkingInfo" }, [
                _vm._v(" 车辆详情 "),
                _c("div", { staticClass: "parkingInfoLine" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "detailsLeft", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("车牌号："),
                        ]),
                        this.formData.treatmentVlaue == 2 &&
                        _vm.tableData[0].originValue !=
                          _vm.tableData[0].currentValue
                          ? _c("span", [
                              _c("u", { staticClass: "ustyleLine" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.tableData[0].originValue)
                                ),
                              ]),
                              _c("a", { staticClass: "uStyleItem" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.tableData[0].currentValue)
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.tableData[0].parkRecordVO.plateNumber
                                )
                              ),
                            ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("入场时间："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.strEntryTime)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("入场记录来源："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.tableData[0].parkRecordVO.entryDataSourceName
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("出入场图像："),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "orderNumberStyle",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.lookPicture(
                                  _vm.tableData[0].parkRecordVO
                                )
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detailsLeft", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("停车时长："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.tableData[0].parkRecordVO.parkTime)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("出场时间："),
                        ]),
                        _vm.formData.treatmentVlaue == 1 &&
                        _vm.tableData[0].originValue !=
                          _vm.tableData[0].currentValue
                          ? _c("span", [
                              _vm.tableData[0].parkRecordVO.strExitTime &&
                              _vm.tableData[0].originValue
                                ? _c("u", { staticClass: "ustyleLine" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.tableData[0].originValue)
                                    ),
                                  ])
                                : _vm._e(),
                              _c("a", { staticClass: "uStyleItem" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.tableData[0].currentValue)
                                ),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.tableData[0].parkRecordVO.strExitTime
                                )
                              ),
                            ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("出场记录来源："),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.tableData[0].parkRecordVO.exitDataSourceName
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c("el-divider"),
              _c("h2", { staticClass: "parkingInfo" }, [
                _vm._v(" 订单详情 "),
                _c("div", { staticClass: "parkingInfoLine" }),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "detailsLeft", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("订单金额："),
                        ]),
                        _vm.tableData[0].originSumShouldPay ==
                        _vm.tableData[0].modifySumShouldPay
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getDiddMoeny(
                                    _vm.tableData[0].originSumShouldPay
                                  )
                                ) + "元"
                              ),
                            ])
                          : _c("span", [
                              _c("u", { staticClass: "ustyleLine" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].originSumShouldPay
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                              _c("a", { staticClass: "uStyleItem" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].modifySumShouldPay
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                            ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "detailsLeft", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("优惠总计："),
                        ]),
                        _vm.tableData[0].originSumAgioMoney ==
                        _vm.tableData[0].modifySumAgioMoney
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getDiddMoeny(
                                    _vm.tableData[0].originSumAgioMoney
                                  )
                                ) + "元"
                              ),
                            ])
                          : _c("span", [
                              _c("u", { staticClass: "ustyleLine" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].originSumAgioMoney
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                              _c("a", { staticClass: "uStyleItem" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].modifySumAgioMoney
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                            ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("实付总计："),
                        ]),
                        _vm.tableData[0].originSumActualMoney ==
                        _vm.tableData[0].modifySumActualMoney
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getDiddMoeny(
                                    _vm.tableData[0].originSumActualMoney
                                  )
                                ) + "元"
                              ),
                            ])
                          : _c("span", [
                              _c("u", { staticClass: "ustyleLine" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].originSumActualMoney
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                              _c("a", { staticClass: "uStyleItem" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].modifySumActualMoney
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                            ]),
                      ]),
                      _c("div", { staticClass: "detailsItem" }, [
                        _c("span", { staticClass: "detailsSpan" }, [
                          _vm._v("欠费总计："),
                        ]),
                        _vm.tableData[0].originSumLackMoney ==
                        _vm.tableData[0].modifySumLackMoney
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.getDiddMoeny(
                                    _vm.tableData[0].originSumLackMoney
                                  )
                                ) + "元"
                              ),
                            ])
                          : _c("span", [
                              _c("u", { staticClass: "ustyleLine" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].originSumLackMoney
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                              _c("a", { staticClass: "uStyleItem" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDiddMoeny(
                                        _vm.tableData[0].modifySumLackMoney
                                      )
                                    ) +
                                    "元"
                                ),
                              ]),
                            ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.beforeRecordsData.length > 0,
                  expression: "beforeRecordsData.length > 0",
                },
              ],
            },
            [
              _c(
                "h2",
                {
                  staticClass: "title",
                  staticStyle: { "padding-top": "10px" },
                },
                [
                  _c("div", { staticClass: "title_icon" }),
                  _c("span", { staticClass: "titlecontent" }, [
                    _vm._v("连续停车订单处理 "),
                  ]),
                ]
              ),
              _c(
                "div",
                [
                  _vm.formData.treatmentVlaue == 2 &&
                  _vm.beforeRecordsData.length > 0
                    ? _c("div", { staticClass: "title2" }, [
                        _c("span", { staticClass: "tip" }, [_vm._v("更改前")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.beforeRecordsData[0].plateNumber) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.beforeRecordsData.length > 0
                    ? _c("processExceptionDetail", {
                        attrs: { tableData: _vm.beforeRecordsData },
                      })
                    : _vm._e(),
                  _vm.formData.treatmentVlaue == 2 &&
                  _vm.afterRecordsData.length > 0
                    ? _c("div", { staticClass: "title2 afterTitlte" }, [
                        _c("span", { staticClass: "tip" }, [_vm._v("更改后")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.afterRecordsData[0].plateNumber) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.formData.treatmentVlaue == 2 &&
                  _vm.afterRecordsData.length > 0
                    ? _c("processExceptionDetail", {
                        attrs: { tableData: _vm.afterRecordsData },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "h2",
                {
                  staticClass: "title",
                  staticStyle: { "padding-top": "10px" },
                },
                [
                  _c("div", { staticClass: "title_icon" }),
                  _c("span", { staticClass: "titlecontent" }, [
                    _vm._v("流程查看 "),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "orderNumberStyle",
                      staticStyle: {
                        "padding-left": "10px",
                        cursor: "pointer",
                      },
                      on: { click: _vm.lookAll },
                    },
                    [_vm._v(" " + _vm._s(_vm.lookAllTag ? "返回" : "查看全部"))]
                  ),
                ]
              ),
              _c("div", { staticClass: "step" }, [
                _c("div", { staticClass: "stepItem" }, [
                  _c("div", {}, [
                    _c("span", { class: false ? "stepIng" : "stepS" }, [
                      _vm._v("1"),
                    ]),
                    _c("span", { class: false ? "stepLing" : "stepL" }),
                  ]),
                  _c("div", { staticClass: "stepContent" }, [
                    _c(
                      "p",
                      {
                        class: false ? "stepContentIng" : "",
                        staticStyle: { "margin-bottom": "6px" },
                      },
                      [_vm._v("发起处理")]
                    ),
                    _c("p", { class: false ? "stepContentIng" : "" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.tableData[0].handleOperatorName) +
                          " " +
                          _vm._s(_vm.tableData[0].handleTime) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("span", { staticClass: "stepI" }, [
                  _vm._v(" " + _vm._s(_vm.tableData[0].statusName) + " "),
                ]),
                _c("div", { staticClass: "stepItem" }, [
                  _c("div", {}, [
                    _c("span", {
                      class:
                        _vm.tableData[0].status == 0 ? "stepL2ing" : "stepL2",
                    }),
                    _c(
                      "span",
                      {
                        class:
                          _vm.tableData[0].status == 0 ? "stepIng" : "stepS",
                      },
                      [_vm._v("2")]
                    ),
                  ]),
                  _c("div", { staticClass: "stepContent" }, [
                    _c(
                      "p",
                      {
                        class:
                          _vm.tableData[0].status == 0 ? "stepContentIng" : "",
                        staticStyle: { "margin-bottom": "6px" },
                      },
                      [_vm._v(" 申诉审核 ")]
                    ),
                    _c(
                      "p",
                      {
                        class:
                          _vm.tableData[0].status == 0 ? "stepContentIng" : "",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tableData[0].status == 0
                                ? ""
                                : _vm.tableData[0].handleOperatorName
                            ) +
                            " " +
                            _vm._s(_vm.tableData[0].appealTime) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("el-divider"),
            ],
            1
          ),
          _c(
            "el-form",
            { ref: "formData", attrs: { model: _vm.formData } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.lookAllTag,
                      expression: "!lookAllTag",
                    },
                  ],
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "title",
                      staticStyle: { "margin-bottom": "16px" },
                    },
                    [
                      _c("div", { staticClass: "title_icon" }),
                      _c("span", { staticClass: "titlecontent" }, [
                        _vm._v("异常订单处理"),
                      ]),
                    ]
                  ),
                  _c("h2", { staticClass: "parkingInfo" }, [
                    _vm._v(" 处理理由 "),
                    _c("div", { staticClass: "parkingInfoLine" }),
                  ]),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      disabled: true,
                      placeholder: "请输入内容",
                      maxlength: "30",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function ($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea",
                    },
                  }),
                ],
                1
              ),
              !_vm.lookAllTag ? _c("el-divider") : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.lookAllTag,
                      expression: "!lookAllTag",
                    },
                  ],
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "title",
                      staticStyle: { "margin-bottom": "16px" },
                    },
                    [
                      _c("div", { staticClass: "title_icon" }),
                      _c("span", { staticClass: "titlecontent" }, [
                        _vm._v("处理方式"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "更改项:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.formData.treatmentVlaue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "treatmentVlaue",
                                        $$v
                                      )
                                    },
                                    expression: "formData.treatmentVlaue",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "el-col",
                            { attrs: { span: 10 } },
                            [
                              _vm._v(" 更改为: "),
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间",
                                },
                                model: {
                                  value: _vm.timeStr,
                                  callback: function ($$v) {
                                    _vm.timeStr = $$v
                                  },
                                  expression: "timeStr",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.treatmentVlaue == 1
                        ? _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _vm._v(" 调整基于: "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.timeChange,
                                    callback: function ($$v) {
                                      _vm.timeChange = $$v
                                    },
                                    expression: "timeChange",
                                  },
                                },
                                _vm._l(_vm.timeChangeoptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.treatmentVlaue == 1 && _vm.timeChange == 2
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _vm._v(" 调整为: 入场时间 + "),
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.timeInput,
                                  callback: function ($$v) {
                                    _vm.timeInput = $$v
                                  },
                                  expression: "timeInput",
                                },
                              }),
                              _vm._v(" 秒 "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.treatmentVlaue == 1 && _vm.timeChange == 1
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _vm._v(" 调整为: "),
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: "选择日期时间",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.timeStr,
                                  callback: function ($$v) {
                                    _vm.timeStr = $$v
                                  },
                                  expression: "timeStr",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.treatmentVlaue == 2
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { display: "flex" },
                              attrs: { span: 12 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "100%",
                                    "line-height": "40px",
                                  },
                                },
                                [_vm._v("车牌号:")]
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: true,
                                  },
                                  model: {
                                    value: _vm.carColor,
                                    callback: function ($$v) {
                                      _vm.carColor = $$v
                                    },
                                    expression: "carColor",
                                  },
                                },
                                _vm._l(_vm.optionsColor, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "plateNumber" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      placeholder: "请输入内容",
                                      disabled: true,
                                    },
                                    model: {
                                      value: _vm.formData.plateNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "plateNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formData.plateNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.treatmentVlaue == 3
                        ? _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _vm._v(" 增/减: "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "80px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.optionsAddItem,
                                    callback: function ($$v) {
                                      _vm.optionsAddItem = $$v
                                    },
                                    expression: "optionsAddItem",
                                  },
                                },
                                _vm._l(_vm.optionsAdd, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formData.treatmentVlaue == 3
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "金额-",
                                    prop: "discountAmount",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      disabled: "",
                                      placeholder: "请输入内容",
                                    },
                                    model: {
                                      value: _vm.formData.discountAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "discountAmount",
                                          $$v
                                        )
                                      },
                                      expression: "formData.discountAmount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  (
                    _vm.tableData[0].statusName == "待审核" && _vm.tag == 1
                      ? false
                      : true
                  )
                    ? _c("h2", { staticClass: "parkingInfo" }, [
                        _vm._v(" 审核理由 "),
                        _c("div", { staticClass: "parkingInfoLine" }),
                      ])
                    : _vm._e(),
                  (
                    _vm.tableData[0].statusName == "待审核" && _vm.tag == 1
                      ? false
                      : true
                  )
                    ? _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入内容",
                          maxlength: "30",
                          "show-word-limit": "",
                          disabled: _vm.tag == 2 ? false : true,
                        },
                        model: {
                          value: _vm.appealReason,
                          callback: function ($$v) {
                            _vm.appealReason = $$v
                          },
                          expression: "appealReason",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lookAllTag,
                      expression: "lookAllTag",
                    },
                  ],
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "title",
                      staticStyle: { "margin-bottom": "16px" },
                    },
                    [
                      _c("div", { staticClass: "title_icon" }),
                      _c("span", { staticClass: "titlecontent" }, [
                        _vm._v("流程记录"),
                      ]),
                    ]
                  ),
                  _c("div", [
                    _vm.tableData[0].statusName != "待审核"
                      ? _c("div", { staticClass: "timelineStyle" }, [
                          _c("div", { staticClass: "timeLeft" }, [
                            _vm._v(_vm._s(_vm.tableData[0].formatAppealTime)),
                          ]),
                          _c("div", { staticClass: "timelineDou" }, [
                            _c("div", [
                              _c("p", [
                                _vm._v(
                                  "申诉处理审核: " +
                                    _vm._s(_vm.tableData[0].statusName)
                                ),
                              ]),
                              _c("div", { staticClass: "timelineContent" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.tableData[0].appealTime)),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "来源:" +
                                      _vm._s(_vm.tableData[0].operateSourceName)
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "操作人:" +
                                      _vm._s(
                                        _vm.tableData[0].appealOperatorName
                                      )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "timelineStyle" }, [
                      _c("div", { staticClass: "timeLeft" }, [
                        _vm._v(_vm._s(_vm.tableData[0].formatHandleTime)),
                      ]),
                      _c("div", { staticClass: "timelineDou" }, [
                        _c("div", [
                          _c("p", [_vm._v("申诉处理提审")]),
                          _c("div", { staticClass: "timelineContent" }, [
                            _c("span", [_vm._v("操作项")]),
                            _c("span", [_vm._v("初始值")]),
                            _c("span", [_vm._v("变更值")]),
                          ]),
                        ]),
                        this.formData.treatmentVlaue == 1
                          ? _c("div", { staticClass: "timelineContent" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].appealTypeName)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].originValue)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].currentValue)),
                              ]),
                            ])
                          : _vm._e(),
                        this.formData.treatmentVlaue == 2
                          ? _c("div", { staticClass: "timelineContent" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].appealTypeName)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].originValue)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].currentValue)),
                              ]),
                            ])
                          : _vm._e(),
                        this.formData.treatmentVlaue == 3
                          ? _c("div", { staticClass: "timelineContent" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].appealTypeName)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].originValue)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].currentValue)),
                              ]),
                            ])
                          : _vm._e(),
                        this.formData.treatmentVlaue == 4
                          ? _c("div", { staticClass: "timelineContent" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].appealTypeName)),
                              ]),
                              _c("span", [_vm._v("正常")]),
                              _c("span", [_vm._v("免费")]),
                            ])
                          : _vm._e(),
                        this.formData.treatmentVlaue == 5
                          ? _c("div", { staticClass: "timelineContent" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.tableData[0].appealTypeName)),
                              ]),
                              _c("span", [_vm._v("正常")]),
                              _c("span", [_vm._v("作废")]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "timelineContent" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.tableData[0].handleTime)),
                          ]),
                          _c("span", [
                            _vm._v(
                              "来源:" +
                                _vm._s(_vm.tableData[0].operateSourceName)
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "操作人:" +
                                _vm._s(_vm.tableData[0].handleOperatorName)
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "closewindow" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lookAllTag,
                      expression: "lookAllTag",
                    },
                  ],
                  on: { click: _vm.lookAll },
                },
                [_vm._v("返回")]
              ),
              _vm.tag == 1 &&
              _vm.tableData[0].status != 0 &&
              _vm.tableData[0].appealType != 4 &&
              _vm.tableData[0].appealType != 1
                ? _c("el-button", { on: { click: _vm.goException } }, [
                    _vm._v("再次发起"),
                  ])
                : _vm._e(),
              _vm.tag == 2
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.adoptBack(1)
                        },
                      },
                    },
                    [_vm._v("通过审核")]
                  )
                : _vm._e(),
              _vm.tag == 2
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.adoptBack(2)
                        },
                      },
                    },
                    [_vm._v("退回审核")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.consoleDetail } }, [
                _vm._v("关闭窗口"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("processPicture", {
        ref: "processPictureID",
        attrs: { dialogVisible: _vm.dialogVisible },
        on: { consolePicture: _vm.consolePicture },
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.bigPic,
              expression: "bigPic",
            },
          ],
          staticClass: "mask",
          style: { "z-index": "10000", "background-image": _vm.picUrl },
          on: {
            click: function ($event) {
              _vm.bigPic = false
            },
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }