<template>
  <!-- todo----连续停车订单处理 -->
   <el-table
    border
    
    :data="tableData"
    style="width: 100%!important"
  >
   <el-table-column label="记录属性">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('recordPropertiesStr'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          },"
        >
          {{ scope.row.recordPropertiesStr }}
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('searchModule.Parking_record_ID')" width="180">
      <template slot-scope="scope">
        <div
          v-clipboard:copy="scope.row.parkRecordId"
          v-clipboard:success="onCopy"
          :class="[treatmentVlaue == 5 && scope.row.recordProperties == 0 ? 'ustyleLine':'',  'orderNumberStyle']"

        >
          {{ scope.row.parkRecordId }}
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('searchModule.Entry_time')" width="160">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('entryTime'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ scope.row.entryTime }}
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('searchModule.Appearance_time')" width="160">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('exitTime'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ scope.row.exitTime }}
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('searchModule.Parking_duration')" width="130">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('parkTime'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ scope.row.parkTime }}
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('searchModule.plate_number')" width="110">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('plateNumber'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ scope.row.plateNumber }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="订单金额(处理前)" width="140">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('originSumShouldPay'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.originSumShouldPay || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="订单金额(处理后)" width="140">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('modifySumShouldPay'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.modifySumShouldPay || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="欠费金额(处理前)" width="140  ">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('originSumLackMoney'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.originSumLackMoney || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="欠费金额(处理后)" width="140">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('modifySumLackMoney'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.modifySumLackMoney || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="实付金额">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('originSumActualMoney'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.originSumActualMoney || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="优惠金额">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('originSumDiscount'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.originSumDiscount || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="应退金额">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('sumShouldRefundMoney'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.sumShouldRefundMoney || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
    <el-table-column label="实退金额">
      <template slot-scope="scope">
        <div
          :class="{
            colorRed:
              scope.row.redFields && scope.row.redFields.includes('sumActualRefundMoney'), 'ustyleLine': treatmentVlaue == 5 && scope.row.recordProperties == 0
          }"
        >
          {{ ((scope.row.sumActualRefundMoney || 0) / 100).toFixed(2) }}元
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "processExceptionDetail",
  data() {
    return {
    };
  },
  watch: {
    // timeInput: {
    //   handler(curVal, oldVal) {
    //     console.log("curVal", curVal);
    //     console.log("oldVal", oldVal);
    //   },
    //   deep: true,
    // },
    // batchType: {
    //   handler(curVal) {
    //     console.log('haha', curVal)
    //   },
    //   deep: true
    // }
  },
  methods: {
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
  },
  props: {
    tableData: {
      default: function () {
        return [];
      },
    },
    treatmentVlaue: {
      default: '',
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .ustyleLine {
    text-decoration: line-through  #000000;
  }
  .title2
    padding 15px 10px
    color #333
  .afterTitlte {
    margin-top: 20px;
    color: #de364c;
  }
  .tip{
    font-weight: bold;
  }

.orderNumberStyle {
  color: #0f6eff;
}
.titlecontent {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2F2F51;
}
.el-table>>> .headerTitleS th {
  background: #F3F7FF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.el-table>>> td {
  height: 40px;
  padding: 0px;
  background: #F9FBFF;
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
}
.colorRed{
  color: #D9001B
}
</style>
